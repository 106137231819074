import {JsonObject} from "@/shared/json/json-object";
import {JsonProperty} from "@/shared/json/json-property";
import {FormGen} from "@/shared/formgen";
import {BaseSiteSettingsObject} from "@/cmsshared-admin/settings/types";
import {Type} from "@/shared/json/helpers";

@JsonObject()
export class SiteSettingsObjectGeneral extends BaseSiteSettingsObject {

  readonly id: string = "settings/general";

  @FormGen({name: "Help Center name", type: "string"})
  @JsonProperty()
  name: string;

  @FormGen({name: "Home URL", type: "string"})
  @JsonProperty()
  homeUrl: string;

  protected getType(): Type<any> {
    return SiteSettingsObjectGeneral;
  }
}

@JsonObject()
export class SiteSettingsObjectColors extends BaseSiteSettingsObject {

  readonly id: string = "settings/colors";

  @FormGen({name: "Primary color", type: "color"})
  @JsonProperty()
  primary: string;

  protected getType(): Type<any> {
    return SiteSettingsObjectColors;
  }
}

@JsonObject()
export class SiteSettingsObjectBrand extends BaseSiteSettingsObject {

  readonly id: string = "settings/brand";

  @FormGen({name: "Logo", description: "Company logo", type: "file", fileMimeTypes: ["image/png", "image/jpg"]})
  @JsonProperty()
  logo: string;

  @FormGen({
    name: "Show Help Center name",
    description: "Display the Help Center name next to the logo",
    type: "boolean"
  })
  @JsonProperty()
  showHelpCenterName: boolean;

  @FormGen({
    name: "Favicon",
    description: "Icon displayed in the address bar of your browser",
    type: "file",
    fileMimeTypes: ["image/png", "image/jpg"]
  })
  @JsonProperty()
  favicon: string;

  protected getType(): Type<any> {
    return SiteSettingsObjectBrand;
  }
}

@JsonObject()
export class SiteSettingsObjectImages extends BaseSiteSettingsObject {

  readonly id: string = "settings/images";

  @FormGen({
    name: "Home hero image",
    description: "Hero image on the home page",
    type: "file",
    fileMimeTypes: ["image/png", "image/jpg"]
  })
  @JsonProperty()
  homeHeroImage: string;
  
  protected getType(): Type<any> {
    return SiteSettingsObjectImages;
  }
}