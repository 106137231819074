import {
  DatabaseReference,
  DataSnapshot,
  get,
  getDatabase,
  ListenOptions,
  onChildAdded,
  ref,
  remove,
  set
} from "@firebase/database";
import {SiteConfigProvider} from "@/shared-site/types";

// These are excluded from provisioning id prefix.
const GLOBAL_PATHS = [
  "users",
  "sync",
]

export enum SystemKeys {
  REL = "__rel__",
}

export class Rel {

  static oneToOnePathFor(name: string, id1: string): string {
    return "/" + SystemKeys.REL + "/" + name + "/" + id1;
  }

  static oneToManyPathFor(name: string, id1: string, id2: string): string {
    return "/" + SystemKeys.REL + "/" + name + "/" + id1 + "/" + id2;
  }

  constructor(readonly name: string, readonly id1: string, readonly id2: string, readonly multiple?: boolean) {
  }

  path(): string {
    if (this.multiple) {
      return Rel.oneToManyPathFor(this.name, this.id1, this.id2);
    }
    return Rel.oneToOnePathFor(this.name, this.id1);
  }
}

export function dbRef(path?: string): DatabaseReference {
  if (!path) {
    path = "";
  }
  if (!path.startsWith("/")) {
    path = "/" + path;
  }
  let prefix = "";
  if (GLOBAL_PATHS.findIndex(globalPath => path.startsWith("/" + globalPath + "/")) < 0) {
      const provisioningId = SiteConfigProvider.getInstance().get().provisioningId;
      prefix = provisioningId ? "/data/" + provisioningId + "/" : "";
  }
  const db = getDatabase();
  return ref(db, prefix + path);
}

export async function dbRef_getVal(path: string): Promise<any | undefined> {
  const objectRef = dbRef(path);
  console.log(objectRef);
  const result = await get(objectRef);
  if (result.exists()) {
    const val = result.val();
    for (const enumKey in SystemKeys) {
      const key = SystemKeys[enumKey];
      if (val[key]) {
        delete val[key];
      }
    }
    return val;
  }
  return undefined;
}

export function dbRef_onChildAdded(path: string, callback: (snapshot: DataSnapshot, previousChildName: string | null) => unknown, options?: ListenOptions): void {
  onChildAdded(dbRef(path), (snapshot, previousChildName) => {
    for (const enumKey in SystemKeys) {
      const key = SystemKeys[enumKey];
      if (snapshot.key === key) {
        return;
      }
    }
    callback(snapshot, previousChildName);
  });
}

export async function dbRef_setVal(path: string, object: any, rel?: Rel) {
  const objectRef = dbRef(path);
  if (rel) {
    const db = getDatabase();
    const relRef = ref(db, objectRef.parent.toString().substring(objectRef.root.toString().length - 1) + rel.path());
    await set(relRef, rel.id2);
  }
  await set(objectRef, object);
}

export async function dbRef_removeVal(path: string, rel?: Rel) {
  const objectRef = dbRef(path);
  await remove(objectRef);
  if (rel) {
    const db = getDatabase();
    const relRef = ref(db, objectRef.parent.toString().substring(objectRef.root.toString().length - 1) + rel.path());
    await remove(relRef)
  }
}
