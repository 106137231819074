import {JsonObject} from "@/shared/json/json-object";
import {SiteSettingsObject} from "@/shared-site/types";
import {DefaultObjectLoader, TypedObject} from "@/shared/types";
import {Type} from "@/shared/json/helpers";

@JsonObject()
export abstract class BaseSiteSettingsObject extends TypedObject implements SiteSettingsObject {

  readonly id: string;

  constructor() {
    super(undefined, undefined);
  }

  createDefaultLoader(): DefaultObjectLoader<any> {
    return new DefaultObjectLoader<any>(this.id, this.getType(), {shared: true});
  }

  protected abstract getType(): Type<any>;
}