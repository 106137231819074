import {JsonObject} from "@/shared-site/json/json-object";
import {PropsData} from "@/elements/types";
import {JsonProperty} from "@/shared-site/json/json-property";
import {Article, Breadcrumbs, Section, Topic, TopicItem} from "@/cmshc-elements/types";

@JsonObject()
export class ArticleData extends PropsData {

  @JsonProperty()
  breadcrumbs: Breadcrumbs;

  @JsonProperty()
  topic: Topic;

  @JsonProperty()
  section: Section;

  @JsonProperty()
  topicItems: TopicItem[];

  @JsonProperty()
  article: Article;

  static from(obj: string | object): ArticleData {
    return this._from(obj, ArticleData);
  }
}

export type ArticleProps = {
  data: ArticleData,
}
