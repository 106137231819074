import {BaseContent, BaseContentLoader} from "../cmsshared-admin/content/types";
import {BaseContentTableDataRowEx, BaseContentTableFragment} from "../cmsshared-admin/content/BaseContentTableFragment";
import {JsonProperty} from "../shared/json/json-property";
import {JsonObject} from "../shared/json/json-object";
import {TableData} from "../shared/tabledata";
import {FormGen} from "../shared/formgen";
import {md5_uuid} from "../shared/md5";
import {getMemberAuth} from "../shared/auth";
import {$KTS} from "@/shared/types";
import {BaseEditContentFragment} from "@/cmsshared-admin/content/BaseEditContentFragment";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow} from "@/shared/StyledComponents";
import {DW_SM} from "@/shared/dimens";

@JsonObject()
export class Document extends BaseContent {

  @FormGen({name: "Status", type: "enum", enumDefaultKey: "draft", enumVariant: "button", enumValues: [$KTS("draft", "Draft"), $KTS("published", "Published"), $KTS("archived", "Archived")]})
  @JsonProperty()
  status: string;

  @FormGen({name: "Title", type: "string"})
  @JsonProperty()
  title: string;

  @FormGen({name: "Subtitle", type: "string"})
  @JsonProperty()
  subtitle: string;

  @FormGen({
    name: "Cover image",
    type: "file",
    fileMimeTypes: ["image/png", "image/jpg"],
    fileStoredDataSource: "documents/cover_images"
  })
  @JsonProperty()
  coverImageUrl: string;

  static createNew(): Document {
    return new Document(md5_uuid(), getMemberAuth().getMemberId(), Date.now());
  }
}

export class DocumentsLoader extends BaseContentLoader<Document> {

  private static instance;

  static getInstance(): DocumentsLoader {
    if (!this.instance) {
      this.instance = new DocumentsLoader();
    }
    return this.instance;
  }

  private constructor() {
    super("documents", "document", Document);
  }
}

export class DocumentTableDataRow extends BaseContentTableDataRowEx {

  @TableData({name: "Status"})
  status: string;

  @TableData({name: "Title", cellStyle: {maxWidth: 192}})
  title: string;
}

export class DocumentsTableFragment extends BaseContentTableFragment<Document, DocumentsLoader, DocumentTableDataRow> {

  createTableDataRow(): DocumentTableDataRow {
    return new DocumentTableDataRow();
  }

  applyTableDataToRow(data: Document, row: DocumentTableDataRow) {
    super.applyTableDataToRow(data, row);
    row.status = data.status;
    row.title = data.title;
  }
}

export class EditDocumentFragment extends BaseEditContentFragment<Document, DocumentsLoader> {

  renderContent(): ReactElement | null {
    return <StyledBoxRow style={{height: "80vh", gap: 0}}>
      <StyledBoxColumn style={{height: "100%", overflowY: "scroll"}}>
        {this.renderEditor({width: DW_SM})}
      </StyledBoxColumn>
    </StyledBoxRow>;
  }
}