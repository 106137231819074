import {JsonObject} from "@/shared-site/json/json-object";
import {JsonProperty} from "@/shared-site/json/json-property";
import {PropsData} from "@/elements/types";
import {Breadcrumbs} from "@/cmshc-elements/types";

@JsonObject()
export class BreadcrumbsData extends PropsData {
  @JsonProperty()
  breadcrumbs: Breadcrumbs;

  static from(obj: string | object): BreadcrumbsData {
    return this._from(obj, BreadcrumbsData);
  }
}

export type BreadcrumbsProps = {
  data: BreadcrumbsData,
}
