import {createSvgIcon, SvgIcon} from "@mui/material";
import {
  AccessTimeOutlined,
  AccountBoxOutlined, AlarmOutlined,
  AppsOutlined,
  ArticleOutlined, AutoAwesomeOutlined, AutoModeOutlined,
  BallotOutlined,
  BoltOutlined,
  BubbleChartOutlined, CalendarTodayOutlined,
  CategoryOutlined,
  ChangeCircleOutlined,
  ChatBubbleOutlineOutlined, CheckCircleOutlined, CloudOutlined,
  ContentPasteSearchOutlined, DevicesOutlined, DoneAllOutlined,
  DynamicFormOutlined,
  EditOutlined,
  ExtensionOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  FormatOverlineOutlined,
  GamepadOutlined, GradingOutlined,
  GroupsOutlined,
  HandymanOutlined,
  HelpOutlineOutlined,
  HomeOutlined,
  ImageOutlined,
  ImageSearchOutlined,
  LockOutlined,
  MailOutlineOutlined, PendingActionsOutlined, PreviewOutlined,
  PublicOutlined, ReceiptLongOutlined,
  SearchOutlined, SecurityOutlined,
  SettingsOutlined,
  ShoppingCartOutlined, SmartToyOutlined,
  StarOutlined,
  TaskAltOutlined,
  TimelineOutlined, UploadFileOutlined,
  ViewComfyOutlined,
  ViewInArOutlined,
  WebAssetOutlined
} from "@mui/icons-material";
import {ReactNode} from "react";

export enum IconType {
  ACCOUNT = "account",
  ALARM = "alarm",
  APPS = "apps",
  ASSETS = "assets",
  RECEIPT = "receipt",
  CALENDAR = "calendar",
  CHAT = "chat",
  COMMUNICATION = "communication",
  CART = "cart",
  CLOCK = "clock",
  MAIL = "mail",
  CHECK_CIRCLE = "check_circle",
  UPLOAD_FILE = "upload_file",
  PENDING_ACTIONS = "pending_actions",
  AUTO_MODE = "auto_mode",
  AUTO_AWESOME = "auto_awesome",
  CLOUD = "cloud",
  DEVICES = "devices",
  REVIEW = "review",
  MULTIPLE_CHECK = "multiple_check",
  ARTICLE = "article",
  PREVIEW = "preview",
  ROBOT = "robot",
  SECURITY = "security",
}

export class Icon {
  constructor(readonly name: string, readonly iconType: typeof SvgIcon) {
  }
}

export const ICONS: Icon[] = [
  new Icon(IconType.COMMUNICATION, ChatBubbleOutlineOutlined),
  new Icon(IconType.RECEIPT, ReceiptLongOutlined),
  new Icon(IconType.CALENDAR, CalendarTodayOutlined),
  new Icon(IconType.REVIEW, GradingOutlined),
  new Icon(IconType.MULTIPLE_CHECK, DoneAllOutlined),
  new Icon(IconType.DEVICES, DevicesOutlined),
  new Icon(IconType.CLOUD, CloudOutlined),
  new Icon(IconType.AUTO_MODE, AutoModeOutlined),
  new Icon(IconType.AUTO_AWESOME, AutoAwesomeOutlined),
  new Icon(IconType.PENDING_ACTIONS, PendingActionsOutlined),
  new Icon(IconType.APPS, AppsOutlined),
  new Icon(IconType.ALARM, AlarmOutlined),
  new Icon(IconType.ASSETS, WebAssetOutlined),
  new Icon(IconType.ARTICLE, ArticleOutlined),
  new Icon(IconType.PREVIEW, PreviewOutlined),
  new Icon(IconType.ROBOT, SmartToyOutlined),
  new Icon("ballot", BallotOutlined),
  new Icon("bolt", BoltOutlined),
  new Icon("category", CategoryOutlined),
  new Icon("bubble_chart", BubbleChartOutlined),
  new Icon(IconType.CART, ShoppingCartOutlined),
  new Icon(IconType.CLOCK, AccessTimeOutlined),
  new Icon("change_circle", ChangeCircleOutlined),
  new Icon(IconType.CHECK_CIRCLE, CheckCircleOutlined),
  new Icon(IconType.CHAT, ChatBubbleOutlineOutlined),
  new Icon("content_search", ContentPasteSearchOutlined),
  new Icon("dynamic_form", DynamicFormOutlined),
  new Icon("edit", EditOutlined),
  new Icon("extension", ExtensionOutlined),
  new Icon("folder", FolderOutlined),
  new Icon("folder_open", FolderOpenOutlined),
  new Icon("format_overline", FormatOverlineOutlined),
  new Icon("gamepad", GamepadOutlined),
  new Icon("groups", GroupsOutlined),
  new Icon("home", HomeOutlined),
  new Icon("image", ImageOutlined),
  new Icon("image_search", ImageSearchOutlined),
  new Icon("lock", LockOutlined),
  new Icon(IconType.MAIL, MailOutlineOutlined),
  new Icon(IconType.ACCOUNT, AccountBoxOutlined),
  new Icon("public", PublicOutlined),
  new Icon("search", SearchOutlined),
  new Icon(IconType.SECURITY, SecurityOutlined),
  new Icon("settings", SettingsOutlined),
  new Icon("star", StarOutlined),
  new Icon("task", TaskAltOutlined),
  new Icon("timeline", TimelineOutlined),
  new Icon("tools", HandymanOutlined),
  new Icon(IconType.UPLOAD_FILE, UploadFileOutlined),
  new Icon("view_in_ar", ViewInArOutlined),
  new Icon("view_comfy", ViewComfyOutlined),
];

export function findIcon(name: string): typeof SvgIcon {
  let icon = ICONS.find(icon => icon.name == name);
  return icon?.iconType || HelpOutlineOutlined;
}

export function createIcon(path: ReactNode) {
  return createSvgIcon(path, "");
}
