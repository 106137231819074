import {BaseCmsAdminApp} from "../cmsshared-admin/BaseCmsAdminApp";
import {CollectionFilterView, CollectionsRegistry} from "../cmsshared-admin/content/types";
import {Article, ArticlesLoader, ArticlesTableFragment, EditArticleFragment} from "./article";
import {User} from "@firebase/auth";
import {ArchiveOutlined, CheckCircleOutlined, DraftsOutlined} from "@mui/icons-material";
import {EditSectionFragment, Section, SectionsLoader, SectionsTableFragment} from "@/app/section";
import {EditTopicFragment, Topic, TopicsLoader, TopicsTableFragment} from "@/app/topic";
import {Document, DocumentsLoader, DocumentsTableFragment, EditDocumentFragment} from "@/app/document";

export class App extends BaseCmsAdminApp {

  protected getTitle(): string {
    return "CMS Help Center";
  }

  protected async onAppInitWithLoggedInUser(user: User): Promise<void> {
    await super.onAppInitWithLoggedInUser(user);
    const registry = CollectionsRegistry.getInstance();
    registry.register({
      displayName: "Documents",
      createNew: () => Document.createNew(),
      clone: (document: Document) => document.clone(Document),
      loader: DocumentsLoader.getInstance(),
      ContentTableFragmentType: DocumentsTableFragment,
      EditContentFragmentType: EditDocumentFragment,
    });
    registry.register({
      displayName: "Topics",
      createNew: () => Topic.createNew(),
      clone: (topic: Topic) => topic.clone(Topic),
      loader: TopicsLoader.getInstance(),
      ContentTableFragmentType: TopicsTableFragment,
      EditContentFragmentType: EditTopicFragment,
    });
    registry.register({
      displayName: "Sections",
      createNew: () => Section.createNew(),
      clone: (section: Section) => section.clone(Section),
      loader: SectionsLoader.getInstance(),
      ContentTableFragmentType: SectionsTableFragment,
      EditContentFragmentType: EditSectionFragment,
    });
    registry.register({
      displayName: "All Articles",
      filterViews: [
        {
          displayName: "Published",
          type: "published",
          iconType: CheckCircleOutlined,
          filter: content => content.status === "published",
        },
        {
          displayName: "Drafts",
          type: "drafts",
          iconType: DraftsOutlined,
          filter: content => content.status === "draft",
        },
        {
          displayName: "Archived",
          type: "archived",
          iconType: ArchiveOutlined,
          filter: content => content.status === "archived",
        },
      ] as CollectionFilterView<Article>[],
      createNew: () => Article.createNew(),
      clone: (article: Article) => article.clone(Article),
      loader: ArticlesLoader.getInstance(),
      ContentTableFragmentType: ArticlesTableFragment,
      EditContentFragmentType: EditArticleFragment,
    });
  }
}