import {BaseContent, BaseContentLoader} from "../cmsshared-admin/content/types";
import {BaseContentTableDataRowEx, BaseContentTableFragment} from "../cmsshared-admin/content/BaseContentTableFragment";
import {JsonProperty} from "../shared/json/json-property";
import {JsonObject} from "../shared/json/json-object";
import {TableData} from "../shared/tabledata";
import {FormGen} from "../shared/formgen";
import {md5_uuid} from "../shared/md5";
import {getMemberAuth} from "../shared/auth";
import {$KTS} from "@/shared/types";
import {BaseEditContentFragment} from "@/cmsshared-admin/content/BaseEditContentFragment";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow} from "@/shared/StyledComponents";
import {DW_SM} from "@/shared/dimens";

@JsonObject()
export class Topic extends BaseContent {

  @FormGen({name: "Document ID", type: "string"})
  @JsonProperty()
  documentId: string;

  @FormGen({name: "Status", type: "enum", enumDefaultKey: "draft", enumVariant: "button", enumValues: [$KTS("draft", "Draft"), $KTS("published", "Published"), $KTS("archived", "Archived")]})
  @JsonProperty()
  status: string;

  @FormGen({name: "Title", type: "string"})
  @JsonProperty()
  title: string;

  static createNew(): Topic {
    return new Topic(md5_uuid(), getMemberAuth().getMemberId(), Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class TopicsLoader extends BaseContentLoader<Topic> {

  private static instance;

  static getInstance(): TopicsLoader {
    if (!this.instance) {
      this.instance = new TopicsLoader();
    }
    return this.instance;
  }

  private constructor() {
    super("topics", "topic", Topic);
  }
}

export class TopicTableDataRow extends BaseContentTableDataRowEx {

  @TableData({name: "Status"})
  status: string;

  @TableData({name: "Title", cellStyle: {maxWidth: 192}})
  title: string;
}

export class TopicsTableFragment extends BaseContentTableFragment<Topic, TopicsLoader, TopicTableDataRow> {

  createTableDataRow(): TopicTableDataRow {
    return new TopicTableDataRow();
  }

  applyTableDataToRow(data: Topic, row: TopicTableDataRow) {
    super.applyTableDataToRow(data, row);
    row.status = data.status;
    row.title = data.title;
  }
}

export class EditTopicFragment extends BaseEditContentFragment<Topic, TopicsLoader> {

  renderContent(): ReactElement | null {
    return <StyledBoxRow style={{height: "80vh", gap: 0}}>
      <StyledBoxColumn style={{height: "100%", overflowY: "scroll"}}>
        {this.renderEditor({width: DW_SM})}
      </StyledBoxColumn>
    </StyledBoxRow>;
  }
}