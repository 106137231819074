import {JsonObject} from "@/shared-site/json/json-object";
import {JsonProperty} from "@/shared-site/json/json-property";
import {BaseListItem, BaseListItemsLoader, DefaultObjectLoader, TypedObject} from "@/shared-site/types";
import {JSON_OBJECT, Type} from "@/shared-site/json/helpers";

@JsonObject()
export abstract class BaseSiteSettingsObject extends TypedObject {

  readonly id: string;

  createDefaultLoader(): DefaultObjectLoader<any> {
    return new DefaultObjectLoader<any>(this.id, this.getType(), {shared: true});
  }

  protected abstract getType(): Type<any>;
}

@JsonObject()
export class SiteSettingsObjectGeneral extends BaseSiteSettingsObject {

  readonly id: string = "settings/general";

  @JsonProperty()
  name: string;

  @JsonProperty()
  homeUrl: string;

  protected getType(): Type<any> {
    return SiteSettingsObjectGeneral;
  }
}

@JsonObject()
export class Breadcrumb {
  @JsonProperty()
  title: string;

  @JsonProperty()
  url: string;
}

@JsonObject()
export class Breadcrumbs {

  @JsonProperty()
  items: Breadcrumb[];
}

@JsonObject()
export class BaseStatusListItem extends BaseListItem {

  @JsonProperty()
  status: string;

  @JsonProperty()
  title: string;
}

abstract class BaseStatusListItemsLoader<T extends BaseStatusListItem> extends BaseListItemsLoader<T> {

  async getOrLoadPublishedListItems(): Promise<T[]> {
    return (await super.getOrLoadListItems()).filter(item => item.status === "published");
  }
}

@JsonObject()
export class Document extends BaseStatusListItem {

  @JsonProperty()
  subtitle: string;

  @JsonProperty()
  coverImageUrl: string;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class DocumentsLoader extends BaseStatusListItemsLoader<Document> {

  private static instance: DocumentsLoader;

  static getInstance(): DocumentsLoader {
    if (!this.instance) {
      this.instance = new DocumentsLoader();
    }
    return this.instance;
  }

  private constructor() {
    super();
  }

  protected basePath(): string {
    return "documents";
  }

  protected deserializeItem(value: any): Document {
    return JSON_OBJECT.deserializeObject(value, Document);
  }

  protected sortOrder(item1: Document, item2: Document): number {
    return item2.created - item1.created;
  }
}

@JsonObject()
export class Topic extends BaseStatusListItem {

  @JsonProperty()
  documentId: string;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class TopicsLoader extends BaseStatusListItemsLoader<Topic> {

  private static instance: TopicsLoader;

  static getInstance(): TopicsLoader {
    if (!this.instance) {
      this.instance = new TopicsLoader();
    }
    return this.instance;
  }

  private constructor() {
    super();
  }

  protected basePath(): string {
    return "topics";
  }

  protected deserializeItem(value: any): Topic {
    return JSON_OBJECT.deserializeObject(value, Topic);
  }

  protected sortOrder(item1: Topic, item2: Topic): number {
    return item2.created - item1.created;
  }
}

export enum TopicItemType {
  SECTION = "section",
  ARTICLE = "article",
}

export function getTopicItemUrl(item: TopicItem): string {
  switch (item.topicItemType) {
    case TopicItemType.SECTION:
      return "/section/" + item.id;
    case TopicItemType.ARTICLE:
      return "/article/" + item.id;
  }
  return undefined;
}

@JsonObject()
export abstract class TopicItem extends BaseStatusListItem {

  @JsonProperty()
  readonly topicItemType: TopicItemType;

  @JsonProperty()
  topicId: string;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

@JsonObject()
export class Section extends TopicItem {

  @JsonProperty()
  readonly topicItemType: TopicItemType = TopicItemType.SECTION;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class SectionsLoader extends BaseStatusListItemsLoader<Section> {

  private static instance: SectionsLoader;

  static getInstance(): SectionsLoader {
    if (!this.instance) {
      this.instance = new SectionsLoader();
    }
    return this.instance;
  }

  private constructor() {
    super();
  }

  protected basePath(): string {
    return "sections";
  }

  protected deserializeItem(value: any): Section {
    return JSON_OBJECT.deserializeObject(value, Section);
  }

  protected sortOrder(item1: Section, item2: Section): number {
    return item2.created - item1.created;
  }
}

@JsonObject()
export class Article extends TopicItem {

  @JsonProperty()
  readonly topicItemType: TopicItemType = TopicItemType.ARTICLE;

  @JsonProperty()
  sectionId: string; // Article can appear under a topic or a section or both.

  @JsonProperty()
  published: number;

  @JsonProperty()
  subtitle: string;

  @JsonProperty()
  body: string;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class ArticlesLoader extends BaseStatusListItemsLoader<Article> {

  private static instance: ArticlesLoader;

  static getInstance(): ArticlesLoader {
    if (!this.instance) {
      this.instance = new ArticlesLoader();
    }
    return this.instance;
  }

  private constructor() {
    super();
  }

  protected basePath(): string {
    return "articles";
  }

  protected deserializeItem(value: any): Article {
    return JSON_OBJECT.deserializeObject(value, Article);
  }

  protected sortOrder(item1: Article, item2: Article): number {
    return item2.created - item1.created;
  }
}