import {BaseContent, BaseContentLoader} from "../cmsshared-admin/content/types";
import {BaseContentTableDataRowEx, BaseContentTableFragment} from "../cmsshared-admin/content/BaseContentTableFragment";
import {JsonProperty} from "../shared/json/json-property";
import {JsonObject} from "../shared/json/json-object";
import {TableData} from "../shared/tabledata";
import {FormGen} from "../shared/formgen";
import {BaseEditContentFragment} from "../cmsshared-admin/content/BaseEditContentFragment";
import {md5_uuid} from "../shared/md5";
import {getMemberAuth} from "../shared/auth";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "@/shared/StyledComponents";
import {Article1} from "@/cmshc-elements/themes/classic/article/Article1";
import {DIVIDER, DW_SM, DW_XL, PD_SM} from "@/shared/dimens";
import {PageFragment, PageFragmentProps, PageFragmentState} from "@/shared/PageFragment";
import {$KTS, Action} from "@/shared/types";
import {DateUtil} from "@/shared/date_util";
import {ArticleData} from "@/cmshc-elements/themes/classic/article/types";

@JsonObject()
export class Article extends BaseContent {

  @JsonProperty()
  readonly topicItemType: string = "article";

  @FormGen({name: "Topic ID", type: "string"})
  @JsonProperty()
  topicId: string;

  @FormGen({name: "Section ID", type: "string"})
  @JsonProperty()
  sectionId: string;

  @FormGen({
    name: "Status",
    type: "enum",
    enumDefaultKey: "draft",
    enumVariant: "button",
    enumValues: [$KTS("draft", "Draft"), $KTS("published", "Published"), $KTS("archived", "Archived")]
  })
  @JsonProperty()
  status: string;

  @FormGen({name: "Published", type: "datenum"})
  @JsonProperty()
  published: number;

  @FormGen({
    name: "Category",
    type: "enum",
    enumDefaultKey: "announcement",
    enumVariant: "select",
    enumValues: [$KTS("announcement", "Announcement"), $KTS("press_release", "Press release"), $KTS("product", "Product")]
  })
  @JsonProperty()
  category: string;

  @FormGen({name: "Title", type: "string"})
  @JsonProperty()
  title: string;

  @FormGen({name: "Subtitle", type: "string"})
  @JsonProperty()
  subtitle: string;

  @FormGen({name: "Body", type: "text"})
  @JsonProperty()
  body: string;

  @FormGen({name: "Attachments", type: "array_file", fileMimeTypes: ["image/png", "image/jpg"]})
  @JsonProperty()
  attachments: string[];

  static createNew(): Article {
    return new Article(md5_uuid(), getMemberAuth().getMemberId(), Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class ArticlesLoader extends BaseContentLoader<Article> {

  private static instance;

  static getInstance(): ArticlesLoader {
    if (!this.instance) {
      this.instance = new ArticlesLoader();
    }
    return this.instance;
  }

  private constructor() {
    super("articles", "article", Article);
  }
}

export class ArticleTableDataRow extends BaseContentTableDataRowEx {

  @TableData({name: "Status"})
  status: string;

  @TableData({name: "Published"})
  published: string;

  @TableData({name: "Category"})
  category: string;

  @TableData({name: "Title", cellStyle: {maxWidth: 192}})
  title: string;
}

export class ArticlesTableFragment extends BaseContentTableFragment<Article, ArticlesLoader, ArticleTableDataRow> {

  createTableDataRow(): ArticleTableDataRow {
    return new ArticleTableDataRow();
  }

  applyTableDataToRow(data: Article, row: ArticleTableDataRow) {
    super.applyTableDataToRow(data, row);
    row.status = data.status;
    row.published = data.published ? DateUtil.formatDateTime(data.published) : "";
    row.category = data.category;
    row.title = data.title;
  }
}

type ArticlePreviewFragmentProps = PageFragmentProps & {
  content: Article,
}

class ArticlePreviewFragment extends PageFragment<ArticlePreviewFragmentProps> {

  protected onCreateState(): PageFragmentState {
    return {
      ...super.onCreateState(),
      title: "Preview",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    return <StyledBoxRow style={{padding: PD_SM, flexGrow: 1}}>
      <StyledSpan/>
      {this.renderToolbarButtonEx(new Action("Render", () => this.forceUpdate()).setVariant("contained"))}
    </StyledBoxRow>
  }

  renderContent(): React.ReactElement {
    return <Article1 data={ArticleData.from({article: this.props.content})}/>;
  }
}

export class EditArticleFragment extends BaseEditContentFragment<Article, ArticlesLoader> {

  renderContent(): ReactElement | null {
    return <StyledBoxRow style={{height: "80vh", gap: 0}}>
      <StyledBoxColumn style={{height: "100%", overflowY: "scroll"}}>
        {this.renderEditor({width: DW_SM})}
      </StyledBoxColumn>
      <StyledBoxColumn style={{height: "100%", overflowY: "scroll", width: DW_XL, borderLeft: DIVIDER}}>
        {this.renderPreview()}
      </StyledBoxColumn>
    </StyledBoxRow>;
  }

  private renderPreview(): ReactElement {
    return <ArticlePreviewFragment content={this.content}/>;
  }
}