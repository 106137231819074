import {Typography} from "@mui/material";
import {BreadcrumbsData} from "@/cmshc-elements/themes/classic/breadcrumbs/types";
import {StyledBoxRow} from "@/shared-site/StyledComponents";
import {HomeOutlined} from "@mui/icons-material";

export function Breadcrumbs1(props: { data: BreadcrumbsData }) {
  return <StyledBoxRow>
    <Typography variant="body2"><a href={"/"}><HomeOutlined fontSize="small"/></a></Typography>
    {props.data.breadcrumbs.items.map(item => <><Typography variant="body2" style={{
      marginLeft: -4,
      marginRight: -8
    }}>&gt;</Typography><Typography variant="body2"><a href={item.url}>{item.title}</a></Typography></>)}
  </StyledBoxRow>;
}
