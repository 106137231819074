import {StyledBoxColumn, StyledBoxRow, StyledContainer} from "@/shared-site/StyledComponents";
import {DIVIDER_COLOR, PD_LG} from "@/shared-site/dimens";
import {Grid, Typography} from "@mui/material";
import {HeadingVariant, headingVariant} from "@/shared-site/utils";
import {mediumGray} from "@/shared-site/colors";
import Markdown from "react-markdown";
import {DateUtil} from "@/shared-site/date_util";
import {Breadcrumbs1} from "@/cmshc-elements/themes/classic/breadcrumbs/Breadcrumbs1";
import {BreadcrumbsData} from "@/cmshc-elements/themes/classic/breadcrumbs/types";
import {ArticleProps} from "@/cmshc-elements/themes/classic/article/types";
import {ArticleOutlined} from "@mui/icons-material";
import {getTopicItemUrl} from "@/cmshc-elements/types";

export function Article1(props: ArticleProps) {
  const article = props.data.article;
  return <StyledContainer size="lg">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <StyledBoxColumn style={{gap: PD_LG}}>
          {props.data.breadcrumbs ?
            <Breadcrumbs1 data={BreadcrumbsData.from({breadcrumbs: props.data.breadcrumbs})}/> : null}
          <Typography variant={headingVariant(3, 4)}>{article.title}</Typography>
          {article.subtitle ? <HeadingVariant
              default_={6}
              style={{
                color: mediumGray,
                fontWeight: "normal"
              }}>
              {article.subtitle}
            </HeadingVariant>
            : null}
          <div style={{background: DIVIDER_COLOR, height: 1}}/>
          <StyledBoxRow>
            {/*<img src="/images/default_user.png" style={{width: 48, height: 48}}/>*/}
            <StyledBoxColumn style={{gap: 0}}>
              {/*<Typography variant="body2">{props.article.creator}</Typography>*/}
              <Typography
                variant="body2">{DateUtil.formatDateTime(article.published || article.created)}</Typography>
            </StyledBoxColumn>
          </StyledBoxRow>
          <div style={{background: DIVIDER_COLOR, height: 1}}/>
          <Typography component="div">
            <Markdown>{article.body}</Markdown>
          </Typography>
          <div style={{background: DIVIDER_COLOR, height: 1}}/>
        </StyledBoxColumn>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
      <StyledBoxColumn>
          {props.data.topicItems.map(item => {
            return <a href={getTopicItemUrl(item)}>
              <StyledBoxRow><ArticleOutlined/><Typography>{item.title}</Typography></StyledBoxRow>
            </a>;
          })}
        </StyledBoxColumn>
      </Grid>
    </Grid>
  </StyledContainer>;
}
