import {JSON_OBJECT, Type} from "@/shared-site/json/helpers";
import {JsonProperty} from "@/shared-site/json/json-property";
import {JsonObject} from "@/shared-site/json/json-object";
import {IconType} from "@/shared-site/icons";

@JsonObject()
export class Link {
  @JsonProperty()
  text: string;
  @JsonProperty()
  url: string;
  @JsonProperty()
  iconType: IconType;
}

@JsonObject()
export class PropsData {

  @JsonProperty()
  id?: string;

  protected static _from<T extends object>(obj: string | object, type: Type<T> | T): T {
    return JSON_OBJECT.deserializeObject(obj, type);
  }
}